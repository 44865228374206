<template>
  <div>
    <el-form :model="data" :rules="rules">
      <el-form-item>
        <div class="x-bc">
          <div class="x-f">
            <span class="marR20 label">页面背景：</span>
            <span>{{ data.style.bgColor }}</span>
          </div>
          <div class="x-f btnListBox">
            <el-button class="marR10" @click="data.style.bgColor = '#f5f5f5'" size="mini">重置
            </el-button>
            <el-color-picker v-model="data.style.bgColor" @change="selecttextColorChange"></el-color-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="x-x">
          <span class="marR20 label">背景图片：</span>
          <div>
            <div class="selectIconBox" @click="getOpenDialog('main')">
              <el-image style="width: 70px; height: 70px" :src="data.style.bgUrl" fit="fill">
                <div slot="error" class="image-slot">
                  <div>
                    <i class="el-icon-plus"></i>
                    <div>上传图片</div>
                  </div>
                </div>
              </el-image>
              <span v-show="data.style.bgUrl" class="replace">替换</span>
              <span v-show="data.style.bgUrl" class="el-icon-delete deleteIcon" @click.stop="getBgUrl('mainBgUrl')"></span>
            </div>
            <div class="annotateGrey">图片尺寸为宽度100%，高度自适应</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="x-f">
          <span class="marR20 label">导航标题：</span>
          <el-input style="flex:1" placeholder="请输入导航标题" size="mini" v-model="data.style.title" />
        </div>
        <div class="annotateGrey">沉浸式轮播在第一个时，不显示标题</div>
      </el-form-item>
      <!-- <el-form-item>
        <div class="x-f">
          <span class="marR20 label">顶部背景：</span>
          <el-radio-group v-model="data.style.topBGTypeObj.type">
            <el-radio :label="1">透明</el-radio>
            <el-radio :label="2">颜色</el-radio>
            <el-radio :label="3">图片</el-radio>
          </el-radio-group>
        </div>
        <div class="x-bc" v-if="data.style.topBGTypeObj.type == 2">
          <div style="flex:1"></div>
          <div class="x-bc topbg">
            <div class="x-f">
              <span class="marR20 label">背景颜色</span>
              <span>{{ data.style.topBGTypeObj.bgColor }}</span>
            </div>
            <div class="x-f btnListBox">
              <el-button class="marR10" @click="data.style.topBGTypeObj.bgColor = ''" size="mini">重置
              </el-button>
              <el-color-picker v-model="data.style.topBGTypeObj.bgColor"
                @change="selecttextColorChange"></el-color-picker>
            </div>
          </div>
        </div>
        <div class="x-bc" v-else-if="data.style.topBGTypeObj.type == 3">
          <div style="flex:1"></div>
          <div class="x-x topbg">
            <span class="marR20 label">背景图片：</span>
            <div>
              <div class="selectIconBox" @click="getOpenDialog('top')">
                <el-image style="width: 70px; height: 70px" :src="data.style.topBGTypeObj.url" fit="fill">
                  <div slot="error" class="image-slot">
                    <div>
                      <i class="el-icon-plus"></i>
                      <div>上传图片</div>
                    </div>
                  </div>
                </el-image>
                <span v-show="data.style.topBGTypeObj.url" class="replace">替换</span>
                <span v-show="data.style.topBGTypeObj.url" class="el-icon-delete deleteIcon" @click.stop="getBgUrl('conBgUrl')"></span>
              </div>
              <div class="annotateGrey">建议图片尺寸为750x120像素</div>
            </div>
          </div>
        </div>
      </el-form-item> -->
      <el-form-item>
        <div class="x-f">
          <span class="marR20 label">功能按钮：</span>
          <el-radio-group v-model="data.style.capsuleType">
            <el-radio :label="1">黑色</el-radio>
            <el-radio :label="2">白色</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
    </el-form>
    <!-- 用户导入图片对话框 -->
    <el-dialog v-dialogDrag title="素材库" :visible.sync="materialOpen" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" :isRadio="true" @accessUrlListCom="getAccessUrlList" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Material from '@/views/components/material/index.vue' //素材库
export default {
  name: 'pageData',
  components: {
    Material,
  },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      indexImg: undefined, //点击的图片下标
      accessUrlList: [], //筛选出来的图片地址数组
      materialOpen: false, //素材库弹窗
      rules: {
        'data.title': [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ]
      },
    }
  },
  methods: {
    getBgUrl(type){
      if (type == 'mainBgUrl') {
        this.$set(this.data.style, 'bgUrl', '')
      }else{
        this.$set(this.data.style.topBGTypeObj, 'url', '')
      }
      this.templateMsg()
    },
    getOpenDialog(type) {
      this.indexImg = type
      this.materialOpen = true
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = []
      this.materialOpen = false
    },
    //上传图片弹窗确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.materialOpen = false
      if (this.indexImg == 'main') {
        this.data.style.bgUrl = this.accessUrlList[0]
      } else {
        this.data.style.topBGTypeObj.url = this.accessUrlList[0]
      }
      this.templateMsg()
    },
    //图片筛出来的url
    getAccessUrlList(value) {
      this.accessUrlList = value
    },
    templateMsg() {
      this.$emit('setTemp', this.data)
      this.$forceUpdate()
    },
    // 文字颜色设置
    selecttextColorChange(value) {
      this.templateMsg()
    }
  }
}
</script>

<style lang="scss" scoped>
.topbg {
  background-color: #f8f9ff;
  padding: 20px 20px 20px 30px;
  width: 80%;
}
//选择图标
.selectIconBox {
  width: 70px;
  height: 70px;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f4f4f4;
  position: relative;

  //替换
  .replace {
    width: 100%;
    position: absolute;
    height: 20px;
    left: 1px;
    right: 1px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    line-height: 20px;
    margin: 0;
    text-align: center;
    display: none;
  }
  .deleteIcon {
    position: absolute;
    height: 20px;
    top: 2px;
    right: 0;
    display: none;
    font-size: 18px;
  }
}

//替换
.selectIconBox:hover .replace {
  display: inline-block;
}
.selectIconBox:hover .deleteIcon {
  display: inline-block;
}
::v-deep .image-slot {
  text-align: center;
}
</style>
