var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { model: _vm.data, rules: _vm.rules } },
        [
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("页面背景："),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.data.style.bgColor))]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.data.style.bgColor = "#f5f5f5"
                        },
                      },
                    },
                    [_vm._v("重置 ")]
                  ),
                  _c("el-color-picker", {
                    on: { change: _vm.selecttextColorChange },
                    model: {
                      value: _vm.data.style.bgColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "bgColor", $$v)
                      },
                      expression: "data.style.bgColor",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-x" }, [
              _c("span", { staticClass: "marR20 label" }, [
                _vm._v("背景图片："),
              ]),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "selectIconBox",
                    on: {
                      click: function ($event) {
                        return _vm.getOpenDialog("main")
                      },
                    },
                  },
                  [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "70px", height: "70px" },
                        attrs: { src: _vm.data.style.bgUrl, fit: "fill" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [
                            _c("div", [
                              _c("i", { staticClass: "el-icon-plus" }),
                              _c("div", [_vm._v("上传图片")]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.data.style.bgUrl,
                            expression: "data.style.bgUrl",
                          },
                        ],
                        staticClass: "replace",
                      },
                      [_vm._v("替换")]
                    ),
                    _c("span", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.data.style.bgUrl,
                          expression: "data.style.bgUrl",
                        },
                      ],
                      staticClass: "el-icon-delete deleteIcon",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.getBgUrl("mainBgUrl")
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "annotateGrey" }, [
                  _vm._v("图片尺寸为宽度100%，高度自适应"),
                ]),
              ]),
            ]),
          ]),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("导航标题："),
                ]),
                _c("el-input", {
                  staticStyle: { flex: "1" },
                  attrs: { placeholder: "请输入导航标题", size: "mini" },
                  model: {
                    value: _vm.data.style.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "title", $$v)
                    },
                    expression: "data.style.title",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "annotateGrey" }, [
              _vm._v("沉浸式轮播在第一个时，不显示标题"),
            ]),
          ]),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("功能按钮："),
                ]),
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.data.style.capsuleType,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "capsuleType", $$v)
                      },
                      expression: "data.style.capsuleType",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [_vm._v("黑色")]),
                    _c("el-radio", { attrs: { label: 2 } }, [_vm._v("白色")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.materialOpen,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.materialOpen = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }