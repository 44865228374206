var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "marB20" }, [
        _c("div", { staticClass: "fontS14c3" }, [_vm._v("组件样式:")]),
        _c("div", { staticClass: "flex marT20" }, [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "200px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = true
                    },
                  },
                },
                [_vm._v("修改样式")]
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v(
              "当前样式：" + _vm._s(_vm.dialogList[_vm.data.type - 1].name)
            ),
          ]),
        ]),
      ]),
      _vm.data.carouselType != 2
        ? _c("div", [
            _c("div", { staticClass: "fontS14c3" }, [_vm._v("轮播点设置:")]),
            _c(
              "div",
              { staticClass: "flex marT20" },
              [
                _c("p", [_vm._v("样式：")]),
                _c(
                  "el-radio-group",
                  {
                    on: { input: _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "•••" } }),
                    _c("el-radio-button", { attrs: { label: "1/5" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex marT20" },
              [
                _c("p", [_vm._v("选中颜色：")]),
                _c("el-color-picker", {
                  model: {
                    value: _vm.data.style.bgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "bgColor", $$v)
                    },
                    expression: "data.style.bgColor",
                  },
                }),
                _c("el-input", {
                  staticStyle: { width: "120px" },
                  model: {
                    value: _vm.data.style.bgColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "bgColor", $$v)
                    },
                    expression: "data.style.bgColor",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _c("div", { staticClass: "fontS14c3 marT20" }, [_vm._v("内容: ")]),
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { margin: "10px 0" },
                  model: {
                    value: _vm.data.carouselType,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "carouselType", $$v)
                    },
                    expression: "data.carouselType",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("普通轮播"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("双层轮播"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.data.contentList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "boxDetail marB10 flex",
                on: {
                  mouseover: function ($event) {
                    return _vm.handleMouseOver(index)
                  },
                },
              },
              [
                _c("div", { staticClass: "x-x marB10" }, [
                  _c("div", { staticClass: "y-c" }, [
                    _c(
                      "div",
                      {
                        staticClass: "selectIconBox x-fc",
                        on: {
                          click: function ($event) {
                            return _vm.getReplace(index, "mainImg")
                          },
                        },
                      },
                      [
                        _c(
                          "el-image",
                          {
                            staticStyle: { width: "70px", height: "70px" },
                            attrs: { src: item.icon, fit: "fill" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("div", [_vm._v("上传图片")]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.icon,
                                expression: "item.icon",
                              },
                            ],
                            staticClass: "replace",
                          },
                          [_vm._v("替换")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "annotateGrey" }, [
                      _vm._v("(主图)"),
                    ]),
                  ]),
                  _vm.data.carouselType == 2
                    ? _c("div", { staticClass: "y-c" }, [
                        _c(
                          "div",
                          {
                            staticClass: "selectIconBox x-fc",
                            staticStyle: { "margin-left": "10px" },
                            on: {
                              click: function ($event) {
                                return _vm.getReplace(index, "bgImg")
                              },
                            },
                          },
                          [
                            _c(
                              "el-image",
                              {
                                staticStyle: { width: "70px", height: "70px" },
                                attrs: { src: item.bgIcon, fit: "fill" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error",
                                  },
                                  [
                                    _c("div", [
                                      _c("i", { staticClass: "el-icon-plus" }),
                                      _c("div", [_vm._v("上传图片")]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.bgIcon,
                                    expression: "item.bgIcon",
                                  },
                                ],
                                staticClass: "replace",
                              },
                              [_vm._v("替换")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "annotateGrey" }, [
                          _vm._v("(背景图)"),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticStyle: { "margin-left": "10px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "x-f link",
                      staticStyle: { color: "cornflowerblue" },
                      on: {
                        click: function ($event) {
                          return _vm.getOpenLink(index)
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          size: "mini",
                          readonly: "",
                          placeholder: "选择链接",
                        },
                        model: {
                          value: item.link.name,
                          callback: function ($$v) {
                            _vm.$set(item.link, "name", $$v)
                          },
                          expression: "item.link.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._m(0, true),
                  _vm.data.type > 1
                    ? _c("div", { staticClass: "x-bc" }, [
                        _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c("span", { staticClass: "fontS14c3 marR20" }, [
                              _vm._v("背景颜色:"),
                            ]),
                            _c("el-color-picker", {
                              model: {
                                value: item.title,
                                callback: function ($$v) {
                                  _vm.$set(item, "title", $$v)
                                },
                                expression: "item.title",
                              },
                            }),
                            _c("span", { staticClass: "fontS14" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.activeIndex === index &&
                        _vm.data.contentList.length > 1,
                      expression:
                        "activeIndex === index && data.contentList.length > 1",
                    },
                  ],
                  staticClass: "el-icon-delete",
                  staticStyle: { position: "absolute", top: "0", right: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.handDelete(index, _vm.data.contentList)
                    },
                  },
                }),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.data.contentList.length < 10,
              expression: "data.contentList.length < 10",
            },
          ],
          staticClass: "x-fc marB20",
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: { size: "mini" },
              on: { click: _vm.addNav },
            },
            [
              _vm._v(
                "+ 添加图片(" + _vm._s(_vm.data.contentList.length) + " / 10)"
              ),
            ]
          ),
        ],
        1
      ),
      _vm.data.carouselType != 2
        ? _c("div", [
            _c(
              "div",
              { staticClass: "x-f marB20" },
              [
                _c("span", { staticClass: "fontS14 marR50" }, [
                  _vm._v("上边距"),
                ]),
                _c("el-slider", {
                  staticClass: "slider",
                  attrs: { min: 0, max: 50 },
                  model: {
                    value: _vm.data.marT,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "marT", $$v)
                    },
                    expression: "data.marT",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "x-f marB20" },
              [
                _c("span", { staticClass: "fontS14 marR50" }, [
                  _vm._v("下边距"),
                ]),
                _c("el-slider", {
                  staticClass: "slider",
                  attrs: { min: 0, max: 50 },
                  model: {
                    value: _vm.data.marB,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "marB", $$v)
                    },
                    expression: "data.marB",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "x-f marB20" },
              [
                _c("span", { staticClass: "fontS14 marR50" }, [
                  _vm._v("左边距"),
                ]),
                _c("el-slider", {
                  staticClass: "slider",
                  attrs: { min: 0, max: 25 },
                  model: {
                    value: _vm.data.marL,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "marL", $$v)
                    },
                    expression: "data.marL",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "x-f marB20" },
              [
                _c("span", { staticClass: "fontS14 marR50" }, [
                  _vm._v("右边距"),
                ]),
                _c("el-slider", {
                  staticClass: "slider",
                  attrs: { min: 0, max: 25 },
                  model: {
                    value: _vm.data.marR,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "marR", $$v)
                    },
                    expression: "data.marR",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "x-f marB20" },
              [
                _c("span", { staticClass: "fontS14 radiuDistance" }, [
                  _vm._v("圆角"),
                ]),
                _c("el-slider", {
                  staticClass: "slider",
                  attrs: { min: 0, max: 50 },
                  model: {
                    value: _vm.data.borRadius,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "borRadius", $$v)
                    },
                    expression: "data.borRadius",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("selectLink", {
        attrs: { OpenLink: _vm.openLink },
        on: {
          "update:OpenLink": function ($event) {
            _vm.openLink = $event
          },
          "update:open-link": function ($event) {
            _vm.openLink = $event
          },
          selectLinkObj: _vm.selectLinkObj,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.openIcon,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openIcon = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "样式选择",
            width: "70%",
            visible: _vm.dialogVisible,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex dl-img" },
            _vm._l(_vm.dialogList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.checkDlStatus(item)
                    },
                  },
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "dl-img-center flex",
                        style: {
                          border:
                            _vm.dlActive === item.Did
                              ? "1px solid #2d8cf0"
                              : "1px solid #f4f4f4",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-image", {
                              staticStyle: { width: "256px", height: "160px" },
                              attrs: { src: item.url, fit: "cover" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "dl-text" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveDldata()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "x-f" }, [
      _c("span", { staticStyle: { color: "#ababab" } }, [
        _vm._v("建议图片尺寸宽度750，高度不限"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }